import React, { Component } from "react";
import "../../App.css";

class Blogs extends Component {
  state = {};
  render() {
    return (
      <div className="content">
        <h2>Our Blogs</h2>
      </div>
    );
  }
}

export default Blogs;
