import React, { Component } from "react";
import "./styles.css";
import hero_image from "../../../../assets/hero-image.svg";
import scroll_icon from "../../../../assets/scroll-icon.svg";
import { Link } from "react-router-dom";

class Header extends Component {
  state = {};
  render() {
    return (
      <div className="header-comp-style">
        <div className="app-header">
          <div>
            <object
              className="hero-img"
              data={hero_image}
              type="image/svg+xml"
            ></object>
          </div>
          <div>
            <h1>MOAT</h1>
            <h2>Investment for Everyone</h2>
          </div>
          <div>
            <a href="#top">
              <img className="scroll-icon" src={scroll_icon}></img>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
