const moatcards = [
  {
    id: 1,
    bio: "Red Apple is a company that...",
    name: "Red Apple",
    author: "aldenwahsono1",
    moatCategories: [
      {
        type: "Brand",
        content:
          "Apple has the highest pricing power and margains of any smartphone brand. iPhones are sold, on average for 2.5x the price of their android counterparts despite their flagship phones having lower specs."
      },
      {
        type: "Network Effect",
        content:
          "Using an Apple product increases the likelihood of buying another Apple product because of its seemless integration and incompatitibility with other brands. Examples of this include iMessage, Apple Music, Airpods, iCloud, etc."
      },
      {
        type: "Switching Costs",
        content:
          'The Apple Ecosystem is dubbed as a "walled garden". Once you\'re in the ecosystem, it is difficult or at least inconvenient to switch out. This also means that android phones are more likely to compete with each other before Apple.'
      }
    ],
    risks: "These are the risks.",
    potential: "This is the potential.",
    conclusion: "Invest in Apple!"
  },

  {
    id: 2,
    bio: "Blue Apple is a company that...",
    name: "Blue Apple",
    author: "aldenwahsono2",
    moatCategories: [
      {
        type: "Brand",
        content:
          "Apple has the highest pricing power and margains of any smartphone brand. iPhones are sold, on average for 2.5x the price of their android counterparts despite their flagship phones having lower specs."
      },
      {
        type: "Network Effect",
        content:
          "Using an Apple product increases the likelihood of buying another Apple product because of its seemless integration and incompatitibility with other brands. Examples of this include iMessage, Apple Music, Airpods, iCloud, etc."
      },
      {
        type: "Switching Costs",
        content:
          'The Apple Ecosystem is dubbed as a "walled garden". Once you\'re in the ecosystem, it is difficult or at least inconvenient to switch out. This also means that android phones are more likely to compete with each other before Apple.'
      }
    ],
    risks: "These are the risks.",
    potential: "This is the potential.",
    conclusion: "Invest in Apple!"
  },
  {
    id: 3,
    name: "Black Apple",
    bio: "Black Apple is a company that...",
    author: "aldenwahsono3",
    moatCategories: [
      {
        type: "Brand",
        content:
          "Apple has the highest pricing power and margains of any smartphone brand. iPhones are sold, on average for 2.5x the price of their android counterparts despite their flagship phones having lower specs."
      },
      {
        type: "Network Effect",
        content:
          "Using an Apple product increases the likelihood of buying another Apple product because of its seemless integration and incompatitibility with other brands. Examples of this include iMessage, Apple Music, Airpods, iCloud, etc."
      },
      {
        type: "Switching Costs",
        content:
          'The Apple Ecosystem is dubbed as a "walled garden". Once you\'re in the ecosystem, it is difficult or at least inconvenient to switch out. This also means that android phones are more likely to compete with each other before Apple.'
      }
    ],
    risks: "These are the risks.",
    potential: "This is the potential.",
    conclusion: "Invest in Apple!"
  },

  {
    id: 4,
    name: "Orange Apple",
    bio: "Orange Apple is a company that...",
    author: "aldenwahsono4",
    moatCategories: [
      {
        type: "Brand",
        content:
          "Apple has the highest pricing power and margains of any smartphone brand. iPhones are sold, on average for 2.5x the price of their android counterparts despite their flagship phones having lower specs."
      },
      {
        type: "Network Effect",
        content:
          "Using an Apple product increases the likelihood of buying another Apple product because of its seemless integration and incompatitibility with other brands. Examples of this include iMessage, Apple Music, Airpods, iCloud, etc."
      },
      {
        type: "Switching Costs",
        content:
          'The Apple Ecosystem is dubbed as a "walled garden". Once you\'re in the ecosystem, it is difficult or at least inconvenient to switch out. This also means that android phones are more likely to compete with each other before Apple.'
      }
    ],
    risks: "These are the risks.",
    potential: "This is the potential.",
    conclusion: "Invest in Apple!"
  },

  {
    id: 5,
    name: "Purple Apple",
    bio: "Purple Apple is a company that...",
    author: "aldenwahsono5",
    moatCategories: [
      {
        type: "Brand",
        content:
          "Apple has the highest pricing power and margains of any smartphone brand. iPhones are sold, on average for 2.5x the price of their android counterparts despite their flagship phones having lower specs."
      },
      {
        type: "Network Effect",
        content:
          "Using an Apple product increases the likelihood of buying another Apple product because of its seemless integration and incompatitibility with other brands. Examples of this include iMessage, Apple Music, Airpods, iCloud, etc."
      },
      {
        type: "Switching Costs",
        content:
          'The Apple Ecosystem is dubbed as a "walled garden". Once you\'re in the ecosystem, it is difficult or at least inconvenient to switch out. This also means that android phones are more likely to compete with each other before Apple.'
      }
    ],
    risks: "These are the risks.",
    potential: "This is the potential.",
    conclusion: "Invest in Apple!"
  }
];

export { moatcards };
