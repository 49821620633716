import React, { Component } from "react";
class Risks extends Component {
  state = {};
  render() {
    return (
      <div>
        <h3>Risks</h3>
        <p>{this.props.risks}</p>
      </div>
    );
  }
}

export default Risks;
