import React, { Component } from "react";
import "../../App.css";
import { sections } from "./services/about.service";
import "./styles.css";

class About extends Component {
  constructor() {
    super();
    this.Section = this.Section.bind(this);
    this.AllSections = this.AllSections.bind(this);
  }

  Section(props) {
    return (
      <div className="about-section">
        <h2 style={{ textAlign: "left" }}>{props.title}</h2>
        <p>{props.text}</p>
      </div>
    );
  }

  AllSections(props) {
    return sections.map((item) => {
      return <this.Section title={item.title} text={item.content} />;
    });
  }

  state = {};
  render() {
    return (
      <div className="content">
        <div className="about-comp-style">
          <h2>About us</h2>
          <this.AllSections />
        </div>
      </div>
    );
  }
}

export default About;
