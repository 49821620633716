import React, { Component } from "react";

import MoatCardThumbnails from "./components/MoatCardThumbnails";
import { Link, Switch, Route } from "react-router-dom";
import CreateMoatCard from "./components/CreateMoatCard";

import MoatCard from "./components/MoatCard";
import "../../App.css";

import "./styles.css";

class MoatCards extends Component {
  state = {};

  render() {
    return (
      <div className="content-small">
        <h2>MoatCards</h2>
        <Link className="nav-btn" to={`${this.props.match.path}`}>
          &lt; Back
        </Link>
        {" | "}
        <Link className="nav-btn" to={`/create`}>
          Create MoatCard &gt;
        </Link>
        <Switch>
          <Route
            exact
            path={`${this.props.match.path}`}
            render={(props) => <MoatCardThumbnails {...props} />}
          />
          <Route
            path={`${this.props.match.path}/:moatcardsId`}
            render={(props) => <MoatCard {...props} />}
          />
          <Route path={`/create`} component={CreateMoatCard} />
        </Switch>
      </div>
    );
  }
}

export default MoatCards;
