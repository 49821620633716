//import logo from "./logo.svg";
import "./App.css";
import Navbar from "./components/NavBar";

import MoatCards from "./scenes/MoatCards";
import Blogs from "./scenes/Blogs";
import Home from "./scenes/Home";
import About from "./scenes/About";
import CreateMoatCard from "./scenes/MoatCards/components/CreateMoatCard";
import Login from "./scenes/Login";
import Signup from "./scenes/Signup";
import UserHomePage from "./scenes/UserHomePage";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import React, { Suspense } from "react";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />

        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/moatcards" component={MoatCards} />
            <Route path="/blogs" component={Blogs} />
            <Route path="/about" component={About} />
            <Route path="/create" component={CreateMoatCard} />
            <Route path="/login" component={Login} />
            <Route path="/signup" component={Signup} />
            <Route path="/homepage" component={UserHomePage} />
          </Switch>
        </Suspense>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
