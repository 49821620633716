import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./styles.css";

class MoatCardNavBar extends Component {
  state = {};

  render() {
    console.log(this.props.url);
    return (
      <div className="mc-navbar">
        <ul>
          <li>
            <Link to={`${this.props.url}`}>About</Link>
          </li>
          <li>
            <Link to={`${this.props.url}/moatcats-comp`}>MOATs</Link>
          </li>
          <li>
            <Link to={`${this.props.url}/potential-comp`}>Potential</Link>
          </li>
          <li>
            <Link to={`${this.props.url}/risks-comp`}>Risks</Link>
          </li>
          <li>
            <Link to={`${this.props.url}/conclusion-comp`}>Conclusion</Link>
          </li>
        </ul>
      </div>
    );
  }
}

export default MoatCardNavBar;
