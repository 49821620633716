import React, { Component } from "react";
import "./styles.css";
import "../../../../App.css";
import MoatCardNavBar from "./components/MoatCardNavBar";

import { moatcards } from "../../services/api";

import logo from "../../../../assets/Apple_logo_black.svg";

import MoatCats from "./components/MoatCats";
import Bio from "./components/Bio";
import Risks from "./components/Risks";
import Potential from "./components/Potential";
import Conclusion from "./components/Conclusion";

class MoatCard extends Component {
  constructor(props) {
    super(props);
    this.getCompanyInfo = this.getCompanyInfo.bind(this);
  }
  state = {};

  getCompanyInfo() {
    return moatcards.find(
      (item) => item.id.toString() === this.props.match.params.moatcardsId
    );
  }

  render() {
    var info = this.getCompanyInfo();
    return (
      <div className="card">
        <div className="comp-card">
          <div className="img-background">
            <img src={logo} alt="company-logo" />
          </div>
          <div className="name-author">
            <div>
              <h2>{info.name} MoatCard</h2>
            </div>
            <div>
              <h3>by {info.author}</h3>
            </div>
          </div>
          <div></div>
        </div>
        <MoatCardNavBar url={this.props.match.url} id={info.id} />
        <div className="bottom-card">
          <Bio id="bio" bio={info.bio} />
          <MoatCats id="moatcats" companyInfo={info} />
          <Risks id="risks" risks={info.risks} />
          <Potential id="potential" potential={info.potential} />
          <Conclusion id="conclusion" conclusion={info.conclusion} />
        </div>
      </div>
    );
  }
}

export default MoatCard;
