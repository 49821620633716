import React, { Component } from "react";
import "./styles.css";

import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import AuthenticationService from "../../services/authentication.service";
import hero_image from "../../assets/hero-image.svg";

class Login extends Component {
  constructor() {
    super();
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  state = {
    email: "",
    password: "",
    redirect: "",
  };

  onSubmit(e) {
    e.preventDefault();

    const user = {
      email: this.state.email,
      password: this.state.password,
    };

    console.log("attempting to login user:", user.email);
    const authenticationService = new AuthenticationService();
    authenticationService.login(user, (data) => {
      if (data.success) {
        console.log("data in login component", data);
        //do something. maybe open a new page for it to be rendered
        this.setState({ redirect: "/homepage" });
      }
    });
  }

  onChangePassword(e) {
    this.setState({ password: e.target.value });
  }

  onChangeEmail(e) {
    this.setState({ email: e.target.value });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={{ pathname: this.state.redirect, state: null }} />;
    }
    return (
      <div className="content">
        <div className="login-comp-style">
          <div className="img-container">
            <object
              className="hero-img"
              data={hero_image}
              type="image/svg+xml"
            ></object>
          </div>
          <form className="form-width" onSubmit={this.onSubmit}>
            <h2 className="page-title">MOAT</h2>
            <div className="form-group">
              <input
                type="email"
                value={this.state.email}
                onChange={this.onChangeEmail}
                required
              />
              <label className="float-label">Email*</label>
            </div>

            <div className="form-group">
              <input
                type="password"
                value={this.state.password}
                onChange={this.onChangePassword}
                required
              />
              <label className="float-label">Password*</label>
            </div>
            <div className="go-btn">
              <input type="submit" value="LOG IN" />
            </div>
          </form>
          <div className="other-options">
            <div className="o-1">
              <Link to="">Forgot username/password?</Link>
            </div>
            <div className="o-2">
              <Link to="/signup">Create a new account?</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
