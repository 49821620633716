import axios from "axios";

class MoatCardsService {
  addCard(moatcard) {
    console.log("about to add card to database");

    moatcard.version = 1; //this is the only information we don't have coming in from the front end
    moatcard.date = Date.now();

    axios
      .post("http://localhost:5000/moatcards/add", moatcard)
      .then(res => console.log(res.data))
      .catch(err => console.log(err));
  }

  getAllCards() {
    console.log("about to get all cards");

    var allmoatcards;

    axios
      .get("http://localhost:5000/moatcards/")
      .then(res => (allmoatcards = res))
      .catch(err => console.log(err));

    return allmoatcards;
  }
}

export default MoatCardsService;
