import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./styles.css";
class Footer extends Component {
  state = {};
  render() {
    return (
      <div className="footer">
        <div>
          <ul>
            <li>
              <Link to="/">Home</Link>{" "}
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/blogs">Our Blogs</Link>
            </li>
            <li>
              <Link to="/moatcards">MoatCards</Link>
            </li>
          </ul>
        </div>
        <p>© Moat 2020</p>
      </div>
    );
  }
}

export default Footer;
