const sections = [
  {
    title: "Our Goal",
    content:
      "I started Libera Invest because I wanted to show that investing is for everyone, not just people in finance. As a liberal arts student interested in investing. I can say, from personal experience, that it can seem daunting and confusing at first - there is a lot of material out there that isn’t very useful or is confusing and the ones that are useful can be difficult to digest. But with the right framework, anyone can succeed in investing. My goal is to help people who want to learn how to invest but don’t know where to start, and hopefully make it a little easier.",
  },
  {
    title: "Our Philosophy",
    content:
      "I started Libera Invest because I wanted to show that investing is for everyone, not just people in finance. As a liberal arts student interested in investing. I can say, from personal experience, that it can seem daunting and confusing at first - there is a lot of material out there that isn’t very useful or is confusing and the ones that are useful can be difficult to digest. But with the right framework, anyone can succeed in investing. My goal is to help people who want to learn how to invest but don’t know where to start, and hopefully make it a little easier.",
  },
  {
    title: "Our Take",
    content:
      "I started Libera Invest because I wanted to show that investing is for everyone, not just people in finance. As a liberal arts student interested in investing. I can say, from personal experience, that it can seem daunting and confusing at first - there is a lot of material out there that isn’t very useful or is confusing and the ones that are useful can be difficult to digest. But with the right framework, anyone can succeed in investing. My goal is to help people who want to learn how to invest but don’t know where to start, and hopefully make it a little easier.",
  },
];

export { sections };
