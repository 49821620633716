import React, { Component } from "react";
import "./styles.css";

import logo from "../../../../../../assets/Apple_logo_black.svg";

class MoatCardThumbnail extends Component {
  state = {};
  render() {
    return (
      <div className="thumbnail">
        <div className="img-container">
          <img src={logo} alt="company-logo" />
        </div>
        <div className="comp-and-author">
          <h2>{this.props.name}</h2>
          <p>by {this.props.author}</p>
        </div>
      </div>
    );
  }
}

export default MoatCardThumbnail;
