import React, { Component } from "react";

class MoatCats extends Component {
  state = {};
  render() {
    var counter = 1;
    console.log("this is the company's information: " + this.props.companyInfo);
    return (
      <div className="moatcats">
        <h3>Moat Categories</h3>
        <ul>
          {this.props.companyInfo.moatCategories.map(category => (
            <li key={counter++}>
              <span style={{ fontWeight: "bold" }}>{category.type}</span>:{" "}
              {category.content}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default MoatCats;
