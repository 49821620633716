import axios from "axios";

class UsersService {
  adduser(newuser) {
    console.log("about to add user to database");

    newuser.joined = Date.now();
    var authOptions = {
      method: "POST",
      url: "http://localhost:5000/users/add",
      data: JSON.stringify(newuser),
      headers: {
        Authorization: "Basic Y2xpZW50OnNlY3JldA==",
        "Content-Type": "application/json",
      },
      json: true,
    };

    axios(authOptions)
      .then((res) => {
        console.log(res.data);
        console.log(res);
      })
      .catch((err) => console.log(err));
  }

  getAllUsers() {
    console.log("about to get all users");

    var allUsers;

    axios
      .get("http://localhost:5000/users/")
      .then((res) => (allUsers = res))
      .catch((err) => console.log(err));

    return allUsers; //make this return only usernames and emails
  }
}

export default UsersService;
