import React, { Component } from "react";
import "../../App.css";
import Header from "./components/Header";
import "./styles.css";
import { Link } from "react-router-dom";

import moatcard_image from "./../../assets/moatcard-image.svg";
import learn_image from "./../../assets/light-bulb.svg";

class Home extends Component {
  state = {};
  render() {
    return (
      <div className="home-comp-style">
        <Header />
        <div className="content">
          <div className="section">
            <h2 id="top">Investing should be simple</h2>
            <p>
              Investing can seem daunting, especially for those who are just
              starting. And, despite living in a world where information is at
              our fingertips, investing can still be a confusing concept.
              <br />
              <br />
              Here at MOAT Invest, we don’t try to reinvent the wheel. Rather,
              we share what we believe to be a very sound approach — investing
              in companies with strong competitive advantages for the long-run
              ushered by many great investors. Our goal is to advocate investing
              and make investing accessible and approachable for everyone.
            </p>
            <button>
              <Link to="/login">GET STARTED</Link>
            </button>
          </div>

          <div className="section">
            <h2 id="top">Explore or create a MoatCard</h2>
            <div className="two-column">
              <div>
                <ul>
                  <h4>1. Quick and Simple</h4>
                  <li>
                    The MoatCard is a simple, one-page document we’ve developed
                    to help turn your ideas into functional investments
                  </li>
                  <h4>2. Solid Framework</h4>
                  <li>
                    The MoatCard is designed to capture all the essential
                    aspects that go into great long-term investments.
                  </li>
                  <h4>3. Share Ideas</h4>
                  <li>
                    Create your own MoatCard or read what other’s have posted.
                  </li>
                </ul>
              </div>
              <div className="moatcard-img">
                <img src={moatcard_image} alt="moatcard-icon" />
              </div>
            </div>
            <button>
              <Link to="/login">GET STARTED</Link>
            </button>
          </div>

          <div className="section">
            <h2 id="top">Learn investing the MOAT way</h2>
            <div className="two-row">
              <div className="learn-img">
                <img src={learn_image} alt="" />
              </div>
              <div>
                <p>
                  View our collection of blog posts designed to teach you the
                  fundamentals of investing smartly and confidently. Learn how
                  to identify the key components of an investable business and
                  discover a host of resources for learning investing concepts.
                </p>
              </div>
            </div>
            <button>
              <Link to="/login">GET STARTED</Link>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
