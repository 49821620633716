import React, { Component } from "react";
import "./styles.css";

import AuthenticationService from "../../services/authentication.service";
import { Redirect } from "react-router-dom";

class UserHomePage extends Component {
  _isMounted = false;
  loading = true;
  loginDetails = {};

  constructor() {
    super();
    this.loginDetails.isAuthenticated = false;
    this.loginDetails.attempted = false;
    this.checkAuthenticated = this.checkAuthenticated.bind(this);
    this.updateStates = this.updateStates.bind(this);
    this.showPage = this.showPage.bind(this);
    this.goToLogin = this.goToLogin.bind(this);
  }

  checkAuthenticated(callback_updateState) {
    const authenticationService = new AuthenticationService();

    authenticationService.ensureAuthenticated((data) => {
      if (data.success) {
        this.loginDetails.isAuthenticated = true;
        this.loginDetails.user = data.user;
      } else {
        this.loginDetails.isAuthenticated = false;
      }
      callback_updateState();
    });
  }

  updateStates() {
    console.log("these are user details:", this.loginDetails);
    if (this.loginDetails.isAuthenticated && this.loginDetails.user) {
      if (this._isMounted) this.setState({ user: this.loginDetails.user });
    } else {
      if (this._isMounted) this.setState({ redirect: "/login" });
    }
    if (this._isMounted) {
      this.loginDetails.attempted = true;
      this.setState({ attempted: this.loginDetails.attempted });
    }
    this.loading = false;
  }

  componentWillMount() {
    this.checkAuthenticated(this.updateStates);
  }
  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate(prevProps, prevState) {
    console.log(
      "new authentication status:",
      this.loginDetails.isAuthenticated
    );
    console.log(prevState, this.state);
    if (!this.loginDetails.isAuthenticated) {
      //currently using this to reload the page when the user is finally authorized
      this.forceUpdate();
    }
  }

  goToLogin() {
    this.setState({ redirect: "/login" });
    return <Redirect to={this.state.redirect} />;
  }

  showPage() {
    if (
      this.loginDetails.isAuthenticated === false &&
      this.loginDetails.attempted === false
    ) {
      return (
        <div>
          <h2>Loading...</h2>
        </div>
      );
    } else if (
      this.loginDetails.attempted === true &&
      this.loginDetails.isAuthenticated === false
    ) {
      return <this.goToLogin />;
    } else {
      return (
        <div>
          <h2>Welcome {this.state.user}</h2>
          <p>User homepage works</p>
        </div>
      );
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    const authenticationService = new AuthenticationService();
    authenticationService.logout();
  }
  state = {
    user: "",
    redirect: "",
    attempted: "",
  };
  render() {
    return <this.showPage />;
  }
}

export default UserHomePage;
