import React, { Component } from "react";
import "./styles.css";
import MoatCardsService from "../../services/moatcards.services";

class CreateMoatCard extends Component {
  state = {};

  constructor(props) {
    super(props);
    this.state = {
      companyname: "",
      username: "",
      about: "",
      email: "",
      moatCategories: [
        {
          name: "Brand",
          content: "",
        },
        {
          name: "Network-Effect",
          content: "",
        },
        {
          name: "Switching Costs",
          content: "",
        },
      ],
      risks: "",
      potential: "",
      conclusion: "",
      moatlevel: "",
    };

    this.moatcardsservice = new MoatCardsService();

    this.MoatCategoriesList = this.MoatCategoriesList.bind(this);

    this.onChangeCompanyName = this.onChangeCompanyName.bind(this);
    this.onChangeConclusion = this.onChangeConclusion.bind(this);
    this.onChangeMoatCategory = this.onChangeMoatCategory.bind(this);
    this.onChangeMoatLevel = this.onChangeMoatLevel.bind(this);
    this.onChangePotential = this.onChangePotential.bind(this);
    this.onChangeRisks = this.onChangeRisks.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeAboutCompany = this.onChangeAboutCompany.bind(this);

    this.onSubmit = this.onSubmit.bind(this);
    this.resetfields = this.resetfields.bind(this);
  }

  onChangeCompanyName(e) {
    //sets the value of state whenever the name changes
    this.setState({
      companyname: e.target.value,
    });
  }
  onChangeUsername(e) {
    //sets the value of state whenever the name changes
    this.setState({
      username: e.target.value,
    });
  }

  onChangeMoatCategory(e) {
    //sets the value of state whenever the name changes
    var updatedMoatCategories = this.state.moatCategories;
    updatedMoatCategories.forEach((moatCategory) => {
      if (moatCategory.name === e.target.name) {
        moatCategory.content = e.target.value;
      }
    });

    this.setState({
      moatCategories: updatedMoatCategories,
    });
  }

  onChangeRisks(e) {
    //sets the value of state whenever the name changes
    this.setState({
      risks: e.target.value,
    });
  }

  onChangePotential(e) {
    //sets the value of state whenever the name changes
    this.setState({
      potential: e.target.value,
    });
  }

  onChangeConclusion(e) {
    //sets the value of state whenever the name changes
    this.setState({
      conclusion: e.target.value,
    });
  }

  onChangeMoatLevel(e) {
    //sets the value of state whenever the name changes
    this.setState({
      moatlevel: e.target.value,
    });
  }

  onChangeEmail(e) {
    //sets the value of state whenever the name changes
    this.setState({
      email: e.target.value,
    });
  }

  onChangeAboutCompany(e) {
    //sets the value of state whenever the name changes
    this.setState({
      about: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();

    const moatcard = {
      companyname: this.state.companyname,
      about: this.state.about,
      username: this.state.username,
      moatCategories: this.state.moatCategories,
      risks: this.state.risks,
      potential: this.state.potential,
      conclusion: this.state.conclusion,
      moatlevel: this.state.moatlevel,
    };

    console.log(moatcard);

    //window.location = "/moatcards"; //take the person back to the moatcards page
    //call service
    this.moatcardsservice.addCard(moatcard);
    this.resetfields();
  }

  MoatCategoriesList() {
    return this.state.moatCategories.map((moatCategory) => (
      <li key={moatCategory.name} className="form-group">
        <label>{moatCategory.name}:</label>
        <textarea
          name={moatCategory.name}
          value={moatCategory.content}
          onChange={this.onChangeMoatCategory}
          className="moatlist-textarea"
        />
      </li>
    ));
  }

  resetfields() {
    this.setState({
      companyname: "",
      username: "",
      email: "",
      moatCategories: [
        {
          name: "Brand",
          content: "",
        },
        {
          name: "Network-Effect",
          content: "",
        },
        {
          name: "Switching Costs",
          content: "",
        },
      ],
      risks: "",
      potential: "",
      conclusion: "",
      moatlevel: "",
    });
  }

  render() {
    return (
      <div className="content">
        <h2>Create MoatCard</h2>
        <div className="create-comp-style">
          <div className="form-width">
            <form onSubmit={this.onSubmit}>
              <div className="form-group">
                <label>Email:</label>
                <input
                  type="text"
                  value={this.state.email}
                  onChange={this.onChangeEmail}
                />
              </div>

              <div className="form-group">
                <label>Company Name:</label>
                <input
                  type="text"
                  value={this.state.companyname}
                  onChange={this.onChangeCompanyName}
                />
              </div>

              <div className="form-group">
                <label>About Company:</label>
                <textarea
                  type="text"
                  value={this.state.aboutcompany}
                  onChange={this.onChangeAboutCompany}
                />
              </div>

              <div className="moatcats">
                <label>Moat Categories:</label>
                <ul>
                  <this.MoatCategoriesList />
                </ul>
              </div>

              <div className="form-group">
                <label>Risks:</label>
                <textarea
                  type="textarea"
                  value={this.state.risks}
                  onChange={this.onChangeRisks}
                />
              </div>

              <div className="form-group">
                <label>Potential:</label>
                <textarea
                  type="text"
                  value={this.state.potential}
                  onChange={this.onChangePotential}
                />
              </div>

              <div className="form-group">
                <label>Moat Level:</label>
                <input
                  type="text"
                  value={this.state.moatlevel}
                  onChange={this.onChangeMoatLevel}
                />
              </div>

              <div className="form-group">
                <label>Conclusion:</label>
                <textarea
                  type="text"
                  value={this.state.conclusion}
                  onChange={this.onChangeConclusion}
                />
              </div>
              <input type="submit" value="Submit Card" className="btn-submit" />
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateMoatCard;
