import React, { Component } from "react";
import { moatcards } from "../../services/api";

import { Link } from "react-router-dom";
import MoatCardThumbnail from "./components/MoatCardThumbnail";

import "./styles.css";
class MoatCardThumbnails extends Component {
  state = {};

  constructor(props) {
    super(props);
    this.displaythumbnails = this.displaythumbnails.bind(this);
    this.wrapthumbnails = this.wrapthumbnails.bind(this);
    console.log("display state: " + this.state.display);
  }

  displaythumbnails(moatcards) {
    return moatcards.map(item => (
      <div key={item.id}>
        <Link to={`${this.props.match.url}/${item.id}`}>
          <MoatCardThumbnail
            author={item.author}
            name={item.name}
          ></MoatCardThumbnail>
        </Link>
      </div>
    ));
  }

  wrapthumbnails(moatcards) {
    return (
      <div className="all-thumbnails">{this.displaythumbnails(moatcards)}</div>
    );
  }

  render() {
    return <div className="content">{this.wrapthumbnails(moatcards)}</div>;
  }
}

export default MoatCardThumbnails;
