import React, { Component } from "react";
import UsersService from "./services/users.service";
import { Link } from "react-router-dom";

import "./styles.css";

class Signup extends Component {
  constructor() {
    super();
    this.onChangeFirstname = this.onChangeFirstname.bind(this);
    this.onChangeLastname = this.onChangeLastname.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);
    this.onChangeBirthdate = this.onChangeUsername.bind(this);
    this.onChangeAvatar = this.onChangeAvatar.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.shouldDisplayError = this.shouldDisplayError.bind(this);
  }
  state = {
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    password: "",
    confirm_password: "",
    birthdate: "",
    avatar: "",
    invalids: [],
  };

  onChangeFirstname(e) {
    this.setState({ firstname: e.target.value }, () => this.validate(0));
  }
  onChangeLastname(e) {
    this.setState({ lastname: e.target.value }, () => this.validate(1));
  }
  onChangeUsername(e) {
    this.setState({ username: e.target.value }, () => this.validate(3));
  }
  onChangeEmail(e) {
    this.setState({ email: e.target.value }, () => this.validate(2));
  }
  onChangePassword(e) {
    this.setState({ password: e.target.value }, () => this.validate(4));
  }
  onChangeConfirmPassword(e) {
    this.setState({ confirm_password: e.target.value }, () => this.validate(5));
  }
  onChangeBirthdate(e) {
    this.setState({ birthdate: e.target.value });
  }
  onChangeAvatar(e) {
    this.setState({ avatar: e.target.value });
  }

  componentDidMount() {
    console.log("about to populate invalids");
    var invalids = []; //starts out clean and then is built up as person interacts with form
    this.setState({ invalids: invalids });
  }

  validate(code) {
    //check database to see if username exists
    //check database to see if email already exists
    //check to see that password matches confirm password
    //check to see that the person is old enough to use Moat
    //if no avatar provided just use a stock avatar image

    /**
     * Key for Error Codes:
     * firstname - 0
     * lastname - 1
     * username - 2
     * email - 3
     * password - 4
     * confirm - 5
     * birthday - 6
     */
    // check if an element exists in array using a comparer function
    // comparer : function(currentElement)

    var invalids = this.state.invalids;

    switch (code) {
      case 0:
        console.log("firstname length:", this.state.firstname.length);
        if (this.state.firstname.length > 0) {
          this.setState(
            {
              invalids: invalids.filter((i) => {
                if (code !== i) return true; //remove all 0 from the invalids list
                return false;
              }),
            },
            () => console.log(this.state.invalids)
          );
          console.log("just removed from firstname invalid");
        } else {
          if (invalids.indexOf(code) === -1) {
            invalids.push(code); //if 0 is not in our invalids then add it
            console.log("just pushed to firstname invalid");
          }
          this.setState({ invalids: invalids }, () =>
            console.log(this.state.invalids)
          );
        }

        break;
      case 1:
        console.log("lastname length:", this.state.lastname.length);
        if (this.state.lastname.length > 0) {
          this.setState(
            {
              invalids: invalids.filter((i) => {
                if (code !== i) return true; //remove all 0 from the invalids list
                return false;
              }),
            },
            () => console.log(this.state.invalids)
          );
          console.log("just removed from lastname invalid");
        } else {
          if (invalids.indexOf(code) === -1) {
            invalids.push(code); //if 0 is not in our invalids then add it
            console.log("just pushed to lastname invalid");
          }
          this.setState({ invalids: invalids }, () =>
            console.log(this.state.invalids)
          );
        }
        break;
      case 3:
        //call service to search for username in database
        var exists = 0;
        if (this.state.username.length > 0 && exists === 0) {
          this.setState(
            {
              invalids: invalids.filter((i) => {
                if (code !== i) return true; //remove all 0 from the invalids list
                return false;
              }),
            },
            () => console.log(this.state.invalids)
          );
        } else {
          if (invalids.indexOf(code) === -1) {
            invalids.push(code);
            console.log("just pushed to username invalid");
          }
          this.setState({ invalids: invalids }, () =>
            console.log(this.state.invalids)
          );
        }
        break;
      case 2:
        if (this.state.email.length > 0) {
          this.setState(
            {
              invalids: invalids.filter((i) => {
                if (code !== i) return true; //remove all 0 from the invalids list
                return false;
              }),
            },
            () => console.log(this.state.invalids)
          );
        } else {
          if (invalids.indexOf(code) === -1) {
            invalids.push(code);
            console.log("just pushed to email invalid");
          }
          this.setState({ invalids: invalids }, () =>
            console.log(this.state.invalids)
          );
        }
        break;
      case 4:
        if (this.state.password.length > 0) {
          this.setState(
            {
              invalids: invalids.filter((i) => {
                if (code !== i) return true; //remove all 0 from the invalids list
                return false;
              }),
            },
            () => console.log(this.state.invalids)
          );
        } else {
          if (invalids.indexOf(code) === -1) {
            invalids.push(code);
            console.log("just pushed to password invalid");
          }
          this.setState({ invalids: invalids }, () =>
            console.log(this.state.invalids)
          );
        }
        break;

      case 5:
        if (
          this.state.confirm_password.length > 0 &&
          this.state.confirm_password === this.state.password
        ) {
          this.setState(
            {
              invalids: invalids.filter((i) => {
                if (code !== i) return true; //remove all 0 from the invalids list
                return false;
              }),
            },
            () => console.log(this.state.invalids)
          );
        } else {
          if (invalids.indexOf(code) === -1) {
            invalids.push(code);
            console.log("just pushed to confirm-password invalid");
          }
          this.setState({ invalids: invalids }, () =>
            console.log(this.state.invalids)
          );
        }
        break;
      default:
        console.log("Err: cannot identify error.");
        break;
    }
  }

  shouldDisplayError(code) {
    if (this.state.invalids.indexOf(code) > -1) return true;
    return false;
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.state.invalids.length === 0) {
      const newuser = {
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        username: this.state.username,
        email: this.state.email,
        password: this.state.password,
        birthdate: this.state.birthdate,
        avatar: this.state.avatar,
      };
      console.log("Account is being created for:", newuser);
      const usersService = new UsersService();
      usersService.adduser(newuser);
    }
  }

  render() {
    return (
      <div className="content">
        <div className="signup-comp-style">
          <form className="form-width" onSubmit={this.onSubmit}>
            <h2 className="page-title">
              Please fill in details to create an account.
            </h2>

            <div className="form-group">
              <input
                type="text"
                value={this.state.firstname}
                onChange={this.onChangeFirstname}
                required
              />
              <label className="float-label">First Name *</label>
              <p
                className={
                  this.shouldDisplayError(0) ? "error-show" : "error-hidden"
                }
              >
                First name is required.
              </p>
            </div>
            <div className="form-group">
              <input
                type="text"
                value={this.state.lastname}
                onChange={this.onChangeLastname}
                required
              />
              <label className="float-label">Last Name *</label>
              <p
                className={
                  this.shouldDisplayError(1) ? "error-show" : "error-hidden"
                }
              >
                Last name is required.
              </p>
            </div>

            <div className="form-group">
              <input
                type="email"
                value={this.state.email}
                onChange={this.onChangeEmail}
                required
              />
              <label className="float-label">Email*</label>
              <p
                className={
                  this.shouldDisplayError(2) ? "error-show" : "error-hidden"
                }
              >
                Email is required.
              </p>
            </div>

            <div className="form-group">
              <input
                type="text"
                value={this.state.username}
                onChange={this.onChangeUsername}
                required
              />
              <label className="float-label">Username*</label>
              <p
                className={
                  this.shouldDisplayError(3) ? "error-show" : "error-hidden"
                }
              >
                Username is required.
              </p>
            </div>

            <div className="form-group">
              <input
                type="password"
                value={this.state.password}
                onChange={this.onChangePassword}
                required
              />
              <label className="float-label">Password*</label>
              <p
                className={
                  this.shouldDisplayError(4) ? "error-show" : "error-hidden"
                }
              >
                Password is required.
              </p>
            </div>

            <div className="form-group">
              <input
                type="password"
                value={this.state.confirm_password}
                onChange={this.onChangeConfirmPassword}
                required
              />
              <label className="float-label">Confirm*</label>
              <p
                className={
                  this.shouldDisplayError(5) ? "error-show" : "error-hidden"
                }
              >
                Confirm password is required.
              </p>
            </div>

            <div className="form-group">
              <label>Day of Birth:</label>
              <div className="controls">
                <select name="dob-day" id="dob-day">
                  <option value="">Day</option>
                  <option value="">---</option>
                  <option value="01">01</option>
                  <option value="02">02</option>
                  <option value="03">03</option>
                  <option value="04">04</option>
                  <option value="05">05</option>
                  <option value="06">06</option>
                  <option value="07">07</option>
                  <option value="08">08</option>
                  <option value="09">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                  <option value="20">20</option>
                  <option value="21">21</option>
                  <option value="22">22</option>
                  <option value="23">23</option>
                  <option value="24">24</option>
                  <option value="25">25</option>
                  <option value="26">26</option>
                  <option value="27">27</option>
                  <option value="28">28</option>
                  <option value="29">29</option>
                  <option value="30">30</option>
                  <option value="31">31</option>
                </select>
                <select name="dob-month" id="dob-month">
                  <option value="">Month</option>
                  <option value="">-----</option>
                  <option value="01">January</option>
                  <option value="02">February</option>
                  <option value="03">March</option>
                  <option value="04">April</option>
                  <option value="05">May</option>
                  <option value="06">June</option>
                  <option value="07">July</option>
                  <option value="08">August</option>
                  <option value="09">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
                <select name="dob-year" id="dob-year">
                  <option value="">Year</option>
                  <option value="">----</option>
                  <option value="2012">2012</option>
                  <option value="2011">2011</option>
                  <option value="2010">2010</option>
                  <option value="2009">2009</option>
                  <option value="2008">2008</option>
                  <option value="2007">2007</option>
                  <option value="2006">2006</option>
                  <option value="2005">2005</option>
                  <option value="2004">2004</option>
                  <option value="2003">2003</option>
                  <option value="2002">2002</option>
                  <option value="2001">2001</option>
                  <option value="2000">2000</option>
                  <option value="1999">1999</option>
                  <option value="1998">1998</option>
                  <option value="1997">1997</option>
                  <option value="1996">1996</option>
                  <option value="1995">1995</option>
                  <option value="1994">1994</option>
                  <option value="1993">1993</option>
                  <option value="1992">1992</option>
                  <option value="1991">1991</option>
                  <option value="1990">1990</option>
                  <option value="1989">1989</option>
                  <option value="1988">1988</option>
                  <option value="1987">1987</option>
                  <option value="1986">1986</option>
                  <option value="1985">1985</option>
                  <option value="1984">1984</option>
                  <option value="1983">1983</option>
                  <option value="1982">1982</option>
                  <option value="1981">1981</option>
                  <option value="1980">1980</option>
                  <option value="1979">1979</option>
                  <option value="1978">1978</option>
                  <option value="1977">1977</option>
                  <option value="1976">1976</option>
                  <option value="1975">1975</option>
                  <option value="1974">1974</option>
                  <option value="1973">1973</option>
                  <option value="1972">1972</option>
                  <option value="1971">1971</option>
                  <option value="1970">1970</option>
                  <option value="1969">1969</option>
                  <option value="1968">1968</option>
                  <option value="1967">1967</option>
                  <option value="1966">1966</option>
                  <option value="1965">1965</option>
                  <option value="1964">1964</option>
                  <option value="1963">1963</option>
                  <option value="1962">1962</option>
                  <option value="1961">1961</option>
                  <option value="1960">1960</option>
                  <option value="1959">1959</option>
                  <option value="1958">1958</option>
                  <option value="1957">1957</option>
                  <option value="1956">1956</option>
                  <option value="1955">1955</option>
                  <option value="1954">1954</option>
                  <option value="1953">1953</option>
                  <option value="1952">1952</option>
                  <option value="1951">1951</option>
                  <option value="1950">1950</option>
                  <option value="1949">1949</option>
                  <option value="1948">1948</option>
                  <option value="1947">1947</option>
                  <option value="1946">1946</option>
                  <option value="1945">1945</option>
                  <option value="1944">1944</option>
                  <option value="1943">1943</option>
                  <option value="1942">1942</option>
                  <option value="1941">1941</option>
                  <option value="1940">1940</option>
                  <option value="1939">1939</option>
                  <option value="1938">1938</option>
                  <option value="1937">1937</option>
                  <option value="1936">1936</option>
                  <option value="1935">1935</option>
                  <option value="1934">1934</option>
                  <option value="1933">1933</option>
                  <option value="1932">1932</option>
                  <option value="1931">1931</option>
                  <option value="1930">1930</option>
                  <option value="1929">1929</option>
                  <option value="1928">1928</option>
                  <option value="1927">1927</option>
                  <option value="1926">1926</option>
                  <option value="1925">1925</option>
                  <option value="1924">1924</option>
                  <option value="1923">1923</option>
                  <option value="1922">1922</option>
                  <option value="1921">1921</option>
                  <option value="1920">1920</option>
                  <option value="1919">1919</option>
                  <option value="1918">1918</option>
                  <option value="1917">1917</option>
                  <option value="1916">1916</option>
                  <option value="1915">1915</option>
                  <option value="1914">1914</option>
                  <option value="1913">1913</option>
                  <option value="1912">1912</option>
                  <option value="1911">1911</option>
                  <option value="1910">1910</option>
                  <option value="1909">1909</option>
                  <option value="1908">1908</option>
                  <option value="1907">1907</option>
                  <option value="1906">1906</option>
                  <option value="1905">1905</option>
                  <option value="1904">1904</option>
                  <option value="1903">1903</option>
                  <option value="1901">1901</option>
                  <option value="1900">1900</option>
                </select>
              </div>
            </div>

            <div className="other-options">
              <div className="o-3">
                <input type="submit" value="SIGN UP" />
              </div>
            </div>
          </form>

          <div className="options">
            <div className="o-1">
              <Link to="/login">Already signed up? Log In</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Signup;
