import axios from "axios";

class AuthenticationService {
  login(user, callback) {
    //console.log("about to log user into database");
    //var success = false;

    var authOptions = {
      method: "POST",
      url: "http://localhost:5000/auth/login",
      data: JSON.stringify(user),
      withCredentials: true,
      headers: {
        //Authorization: "Basic Y2xpZW50OnNlY3JldA==",
        "Content-Type": "application/json",
      },
      json: true,
    };

    axios(authOptions)
      .then((res) => {
        console.log(res.data);
        callback(res.data);
      })
      .catch((err) => console.log(err));
  }

  //Check if user is authenticated before rendering any page that requires authentication
  ensureAuthenticated(callback) {
    var authOptions = {
      method: "GET",
      url: "http://localhost:5000/auth/login",
      withCredentials: true,
      headers: {
        // Authorization: "Basic Y2xpZW50OnNlY3JldA==",
        "Content-Type": "application/json",
      },
      json: true,
    };

    axios(authOptions)
      .then((res) => {
        console.log(res.data);
        callback(res.data);
      })
      .catch((err) => console.log(err));
  }

  //logout: used to log a user out of the application
  logout() {
    var authOptions = {
      method: "GET",
      url: "http://localhost:5000/auth/logout",
      withCredentials: true,
      headers: {
        // Authorization: "Basic Y2xpZW50OnNlY3JldA==",
        "Content-Type": "application/json",
      },
      json: true,
    };

    axios(authOptions)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => console.log(err));
  }
}

export default AuthenticationService;
